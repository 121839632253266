import React from "react";
import InfoContactUsForm from "../../components/info-contact-us-form";
import Layout from "../../components/layout";
import Quote from "../../components/quote";
import {navigate} from "gatsby";

const subscriberLpZendeskEfficiencyChecklist = process.env.GATSBY_SUBSCRIBER_LP_ZENDESK_EFFICIENCY_CHECKLIST_FORM_TAG;


const Main = ({location}) => (
  <section className="container-xl p-0">
    <div className="row m-auto">
      <div className="col-12 col-md-6 py-5 pr-md-5 py-md-6">
        <h3>Make the Most of Zendesk</h3>
        <h1>
          So you have Zendesk, but you suspect you’re not using all the
          features?
        </h1>
        <p>
          Use this checklist to review your instance and ensure you’re set up
          for success.
        </p>
        <h2>In Our Zendesk Efficiency Checklist:</h2>
        <ul>
          <li>Do you know what you paid for?</li>
          <li>Review your Guide setup</li>
          <li>Integrate your brand</li>
          <li>Find your pain points</li>
          <li>Segment your content</li>
        </ul>
      </div>
      <div className="col-12 col-md-6 bg-primary py-6 px-lg-6">
        <h3 className="text-center text-white">
          Want to make the most of your Zendesk?
        </h3>
        <h3>Download our Zendesk Efficiency Checklist</h3>
        <div className="bg-light p-4 bg-light mt-3">
          <InfoContactUsForm
            location={location}
            tags={[subscriberLpZendeskEfficiencyChecklist]}
            onSubmitSuccess={() => navigate('/lp/ty-zendesk-efficiency-checklist')}
            buttonText="YES, DOWNLOAD CHECKLIST"/>
        </div>
      </div>
    </div>
  </section>
);

const Testimonial = () => (
  <section className="py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 align-self-center">
          <h2>What our clients are saying regarding our Zendesk work</h2>
        </div>
        <div className="col-12 col-md-6">
          <Quote
            quote="729 allowed us the flexibility to self-implement, but were with us every step of the way. They answered questions, brainstormed, provided solutions and best practices for anything we needed. They are personable, caring, and reliable. I would recommend working with them, and feel confident everyone would feel the same value we did."
            name="KAYLA FISCHER"
            company="HireVue, Inc"
          />
        </div>
      </div>
    </div>
  </section>
);

const Index = ({location}) => (
  <Layout>
    <Main location={location}/>
    <Testimonial/>
  </Layout>
);

export default Index;
